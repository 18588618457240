.form-signin-heading {
    font-size: 56px;
}

.form-signin-sub-heading {
    font-size: 24px;
}

.form-signin-wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
    width: 400px;
}

.form-signin {
    max-width: 400px;
    padding: 15px;
}

.btn-block {
    display: block;
    width: 100%;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    border: 0;
}