#spinnerContainer {
  position: fixed; z-index: 9999; width: 50px; height: 50px; top: calc( 50% - ( 50px / 2) ); right: calc( 50% - ( 50px / 2) );
}
#spinnerContainer > div {
  width: 50px; height: 50px;
}
#spinnerBackdrop {
  z-index: 9998;
}
.spinner-centered {
  display: block;
  position: fixed;
  z-index: 1031; /* High z-index so it is on top of the page */
  top: calc( 50% - ( 10px / 2) ); /* where ... is the element's height */
  right: calc( 50% - ( 10px / 2) ); /* where ... is the element's width */
}